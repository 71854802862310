
@keyframes showcard {
    0% {
        opacity: 0;
        transform: rotateY(90deg)
    }
    100% {
      opacity: 1;
      transform: rotateY(0)
    }
  }
  
  @keyframes hidecard {
    0% {
        opacity: 0;
        transform: scale(0)
    }
    100% {
      opacity: 1;
      transform: scale(1)
    }
  }