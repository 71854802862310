.section {
    color: #fff;
    padding-bottom: 4rem;
}

.header {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 3rem;
    svg {
        color: #FFB800;
        position: absolute;
        height: 18px;
        bottom: -11px;
    }
}

.heading {
    margin-top: -1px;
    display: inline-flex;
    clip-path: polygon(0 0, 100% 0, calc(100% - 15px) 100%, 15px 100%);
    text-transform: uppercase;
    padding: 0.75rem;
    width: 495px;
    font-family: Rajdhani;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    justify-content: center;
    color: #FFFFFF;
    background-color: #000;
}

.subheading {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 1rem 0;
    span {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 100%;
        color: #FFFFFF;
        opacity: 0.6;
        text-transform: none;
    }
}

.cards {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 2.5rem;
}

.card {
    grid-column: auto;
    overflow: hidden;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 1rem 100%, 0% calc(100% - 1rem));
    background-color: #000;
    &.slim {
        grid-column: span 2 / span 2;
    }
    display: flex;
    flex-direction: column;
}

.visibility {
    position: absolute;
    background: #15171E;
    clip-path: polygon(0% 0%, 100% 0%, calc(100% - 0.75rem) 100%, 0% 100%);
    font-family: Rajdhani;
    font-weight: bold;
    font-size: 11px;
    line-height: 150%;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 0.25em 1em;
    padding-right: 1rem;
    span {
        padding-top: 2px;
    }
}

.visibilityIcon {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
}

.information {
    padding: 1.75rem 1rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.banner {
    aspect-ratio: 16 / 9;
    max-width: 100%;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Rajdhani;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 1rem;
    img {
        width: 1.75rem;
    }
    &:hover {
        color: #ddd;
        text-decoration: underline;
    }
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 1em 0;
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
}

.informationStage {
    margin-top: auto;
    padding: 1em 0;
    border-top: 1px solid #38393D;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.informationLabel {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
}

.socials {
    display: flex;
    gap: 1em;
    svg {
        display: block;
        width: 1rem;
        height: 1rem;
    }
}

.social {
    display: block;
    padding: .5rem;
    background: #15171E;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.black {
    background-color: #000;
    .heading,
    .card {
        background-color: #15171E;
    }
    .social {
        background-color: #000;
    }
}