.control {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    user-select: none;
    &:hover {
        img {
            opacity: 0.8;
        }
    }
}

.controlNext {
    right: 0;
    left: auto;
}

.item {
    display: flex;
    gap: 1rem;
    font-family: Rajdhani;
    width: 100%;
    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
}

.image,
.thumbnail {
    aspect-ratio: 16 / 9;
    border-radius: 2px;
}

.image {
    flex: none;
    width: 60%!important;
    clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%, 0 0);
    @media screen and (max-width: 640px) {
        width: 100% !important;
    }
}

.information {
    color: white;
    flex: 1;
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 1rem;
    margin-top: 2rem;
    text-transform: uppercase;
    @media screen and (max-width: 640px) {
        margin-top: 0;
        font-size: 24px;
    }
    img {
        margin-left: auto;
        width: 1.5rem!important;
    }
}

.description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #D4D7E1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and (max-width: 640px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.informationPurchase {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    font-family: Poppins;
    div {
        width: 50%;
    }
    p {
        font-style: normal;
        font-weight: bold;
        font-family: Rajdhani;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.5;
    }
}

.countdown {
    display: block;
    width: 68%;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
}

.actionBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #6CDB00;
    clip-path: polygon(0 5px, 5px 0, 100% 0, 100% calc(100% - 5px), calc(100% - 5px) 100%, 0 100%);
    font-family: Rajdhani;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #0D0F15;
    display: inline-flex;
    padding: 0.8em 1.5em 0.75em 1.5em;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    img {
        margin-left: 1em;
        width: 1.25em!important;
    }
    @media screen and (max-width: 640px) {
        width: 100%;
        position: relative;
        margin-top: 8px;
    }
}

.heading {
    position: absolute;
    clip-path: polygon(0 0, 100% 0%, calc(100% - 20px) 100%, 0% 100%);
    width: 30%;
    z-index: 10;
    top: 0;
    font-family: Rajdhani;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.32em;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    padding-left: 3rem;
    color: #fff;
    background-color: rgb(21, 23, 30);
    span {
        color: #6CDB00;
    }
}

.socials {
    display: flex;
    gap: 1.5em;
    margin: 1.5rem 0;
    svg {
        display: block;
        width: 1rem;
        height: 1rem;
        color: #fff;
        &:hover {
            color: #ddd;
        }
    }
}

.social {
    display: block;
    border-radius: 4px;
    cursor: pointer;
}