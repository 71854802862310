$list-heading: 4rem;
.header {
    display: flex;
    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
}

.row {
    display: grid;
    grid-template-columns: 160px 1fr;
    gap: 20px;
    margin-top: 1rem;
    border-radius: 2px;
    padding: 0.5rem;
    &:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }
    @media screen and (max-width: 640px) {
        gap: 8px;
        flex-direction: column;
        grid-template-columns: 100px 1fr;
    }
}

.itemInformation {
    display: grid;
    grid-template-columns: 160px 1fr;
    gap: 10px;
    @media screen and (max-width: 640px) {
        grid-template-columns: 1fr;
    }
}

.detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 150px));
    gap: 10px;
    justify-content: center;
    @media screen and (max-width: 640px) {
        grid-template-columns: 80px 80px 80px;
        justify-content: start;
    }
}

.link {
    // width: 45%;
    display: flex;
    gap: 1rem;
    // align-items: center;
    img {
        width: 10rem;
        aspect-ratio: 16 / 9;
        @media screen and (max-width: 640px) {
            object-fit: cover;
        }
    }
}

.title {
    h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        @media screen and (max-width: 640px) {
            font-size: 16px;
        }
    }
    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        opacity: 0.5;
        @media screen and (max-width: 640px) {
            font-size: 12px;
        }
    }
}

.info {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #fff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    // justify-content: center;
    span {
        font-family: Rajdhani;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        color: #FFFFFF;
        opacity: 0.5;
        @media screen and (max-width: 640px) {
            font-size: 12px;
        }
    }
    p {
        display: flex;
        align-items: center;
        @media screen and (max-width: 640px) {
            font-size: 12px;
        }
    }
    img {
        width: 1.5rem;
    }
}

.header {
    display: flex;
    color: white;
    background-color: #15171E;
    .heading {
        width: 35%;
    }
}

.heading {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 1;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #000;
    height: $list-heading;
    display: inline-flex;
    align-items: center;
    position: relative;
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        content: ' ';
        height: $list-heading;
        width: $list-heading;
        background: linear-gradient(to bottom right, #000 0%, #000 50%, #15171E 50%, #15171E 100%);
    }
    &:before {
        position: absolute;
        left: -100vw;
        content: ' ';
        display: block;
        width: 100vw;
        height: 100%;
        background: #000;
    }
    @media screen and (max-width: 640px) {
        width: 100% !important;
        &:after,
        &::before {
            display: none;
        }
    }
}

.filters {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8%;
    input,
    textarea,
    select,
    button {
        padding: 0.5em 1.5em;
        margin: 0;
        box-sizing: border-box;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        background-color: #27272D;
        color: #fff;
        border: none;
        border-radius: 2px;
        appearance: none;
        border: 1px solid transparent;
        &:focus {
            outline: none;
            border-color: #555;
        }
    }
    select {
        padding-right: 2.5em;
    }
    input {
        padding-left: 2.5em;
    }
    @media screen and (max-width: 640px) {
        justify-content: start;
        padding: 4px;
        input {
            width: 100%;
        }
    }
}

.filter {
    margin-right: 1em;
    position: relative;
    svg {
        position: absolute;
        top: 0.75em;
        right: 0.75em;
        width: 1em;
        height: 1em;
    }
    &:last-child {
        margin-right: 0;
    }
}

.filterIconLeft {
    right: auto;
    left: 0.75em;
}

.pagination {
    color: #fff;
    display: inline-flex;
    gap: 0.25em;
    span {
        display: inline-flex;
        width: 2.5em;
        height: 2.5em;
        background: rgba(39, 39, 45, 0.5);
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
        border: 1px solid transparent;
        &:hover {
            background: rgba(39, 39, 45, 1);
        }
    }
}

.paginationActive {
    border-color: #72F34B!important;
    color: #72F34B;
}

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @media (min-width: 640px) {
        max-width: 640px;
    }
    @media (min-width: 768px) {
        max-width: 768px;
    }
    @media (min-width: 1024px) {
        max-width: 960px;
    }
    @media(min-width: 1280px) {
        max-width: 1180px;
    }
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: #fff;
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.loader {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
    circle {
        opacity: 0.25;
    }
    path {
        opacity: 0.75;
    }
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}